/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // Scroll Up
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 1500);
          return false;
        });

        $('.pageGallery').each(function(){
          var pageGallery = $(this).attr('id');
          $('#' + pageGallery).lightSlider({
            gallery:true,
            item:1,
            loop:true,
            thumbItem: 1,
            slideMargin:0,
            enableDrag: false,
            controls: false,
            currentPagerPosition:'left',
            onSliderLoad: function(el) {
              el.lightGallery({
                selector: '#' + pageGallery + ' .lslide'
              });
            }
          });
        });

        // toggles hamburger and nav open and closed states
        var removeClass = true;
          $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        $("html").click(function () {
          if (removeClass) {
            $(".hamburger").removeClass('is-active');
            $("#sideNav").removeClass('sideNav-open');
            $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        });

        // swipe to close
        var lastScrollLeft = 0;
        $(window).scroll(function() {
          var documentScrollLeft = $(document).scrollLeft();
          if (lastScrollLeft !== documentScrollLeft) {
            if (removeClass) {
              $(".hamburger").removeClass('is-active');
              $(".sideNav").removeClass('sideNav-open');
              $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
            lastScrollLeft = documentScrollLeft;
          }
        });

        document.google_maps = {
            initialize_gmap: function(map_lat, map_long, zoom) {
                var map;
                var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
                var isDraggable = w > 480 ? true : false;
                var settings = {
                    zoom: zoom,
                    center: {lat: map_lat, lng: map_long},
                    scrollwheel: false,
                    draggable: isDraggable,
                    mapTypeControl: true,
                    mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
                    navigationControl: true,
                    navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
                    mapTypeId: google.maps.MapTypeId.ROADMAP};
                map = new google.maps.Map(document.getElementById("map_canvas"), settings);
                var center;
                function calculateCenter() {
                    center = map.getCenter();
                }
                google.maps.event.addDomListener(map, 'idle', function() {
                    calculateCenter();
                });
                google.maps.event.addDomListener(window, 'resize', function() {
                    map.setCenter(center);
                });
                return map;
            },
            add_marker: function(map, point_lat, point_long, address, title, directions) {
                var infowindow = new google.maps.InfoWindow();
                var point = new google.maps.LatLng(point_lat, point_long);
                var contentString = '<div id="gmapContent" style="text-align:left;">'+
                  '<a href="' + directions + '" target="_blank"><h4>' + title + '<br><small>(Click for directions)</small></h4></a>'+
                  '<div id="bodyContent">'+
                  '<p>' + address + '</p>'+
                  '</div>'+
                  '</div>';
                var companyMarker = new google.maps.Marker({position: point, map: map, title: title});
                google.maps.event.addListener(companyMarker, 'click', function() {
                    infowindow.setContent(contentString);
                    infowindow.open(map,this);
                });
            },
            get_map_data: function(map_name) {
                var point_lat = parseFloat($(map_name).find('.map-data').find('.point_lat').data('pointlat'));
                var point_long = parseFloat($(map_name).find('.map-data').find('.point_long').data('pointlong'));
                var address = $(map_name).find('.map-data').find('.address').data('address');
                var title = $(map_name).find('.map-data').find('.title').data('title');
                var directions = $(map_name).find('.map-data').find('.directions').data('directions');
                return {'lat': point_lat, 'long': point_long, 'address': address, 'title': title, 'directions': directions};
            },
            get_canvas: function() {
                if($('#map_canvas').length) {
                    var map_lat = parseFloat($('#map_canvas').siblings('.map-data').find('.map_lat').data('maplat'));
                    var map_long = parseFloat($('#map_canvas').siblings('.map-data').find('.map_long').data('maplong'));
                    var zoom = parseFloat($('#map_canvas').siblings('.map-data').find('.zoom').data('zoom'));
                    return {'lat': map_lat, 'long': map_long, 'zoom': zoom};
                }
            }
        };
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('#homeGallery').lightGallery({
            thumbnail:false,
            item: 3
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    'page_has_map': {
      init: function() {
        document.initMap = function() {
            var google_maps = document.google_maps;
            gmc = google_maps.get_canvas();
            map = google_maps.initialize_gmap(gmc.lat, gmc.long, gmc.zoom);
            if($('.location-map-bangor').length) {
                gmd = google_maps.get_map_data('.location-map-bangor');
                google_maps.add_marker(map, gmd.lat, gmd.long, gmd.address, gmd.title, gmd.directions);
            }

            if($('.location-map-bucksport').length) {
                gmd = google_maps.get_map_data('.location-map-bucksport');
                google_maps.add_marker(map, gmd.lat, gmd.long, gmd.address, gmd.title, gmd.directions);
            }
        };

        var maps_api_key = 'AIzaSyDsI3cMec-y2nEXkHrZwy5TIKppKmsyOkw';
        var maps_callback = 'document.initMap';
        var maps_api_url = '//maps.google.com/maps/api/js';
        var maps_script_tag = document.createElement('script');
        maps_script_tag.setAttribute('type', 'text/javascript');
        maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
        maps_script_tag.setAttribute('defer', '');
        maps_script_tag.setAttribute('async', '');
        document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    },
    'page_has_map_single': {
      init: function() {
          document.initMap = function() {
              var google_maps = document.google_maps;
              //gmc = google_maps.get_canvas();
              if($('.location-map').length) {
                  gmd = google_maps.get_map_data('.location-map');
                  map = google_maps.initialize_gmap(gmd.lat, gmd.long, 14);
                  google_maps.add_marker(map, gmd.lat, gmd.long, gmd.address, gmd.title, gmd.directions);
              }
          };

          var maps_api_key = 'AIzaSyDsI3cMec-y2nEXkHrZwy5TIKppKmsyOkw';
          var maps_callback = 'document.initMap';
          var maps_api_url = '//maps.google.com/maps/api/js';
          var maps_script_tag = document.createElement('script');
          maps_script_tag.setAttribute('type', 'text/javascript');
          maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
          maps_script_tag.setAttribute('defer', '');
          maps_script_tag.setAttribute('async', '');
          document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
